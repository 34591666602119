import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
import BlogPost from 'common/src/components/BlogPost';
import SectionWrapper, {
  SectionHeader,
  TitleArea,
  LinkArea,
  Text,
  PostArea,
} from './blogSection.style';

const BlogSection = () => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        posts {
          id
          thumbUrl {
            publicURL
          }
          title
          excerpt
          btnUrl
          btnText
        }
      }
    }
  `);

  return (
    <SectionWrapper id="events">
      <Container width="1260px">
        <SectionHeader>
          <TitleArea>
            <Heading content="Upcoming Events" />
            <Text>
              View the following schedule for upcoming events, meet ups, and
              information!
            </Text>
          </TitleArea>
          {/* <LinkArea>
            <a href="#1" className="text__btn">
              <span className="text">All events</span>
              <span className="arrow" />
            </a>
          </LinkArea> */}
        </SectionHeader>
        <PostArea>
          {data.charityJson.posts.map(item => (
            <BlogPost
              key={`blog__post-key${item.id}`}
              thumbUrl={item.thumbUrl.publicURL}
              title={item.title}
              excerpt={item.excerpt}
            />
          ))}
        </PostArea>
      </Container>
    </SectionWrapper>
  );
};

export default BlogSection;
