//Note: Modified from ../containers/Charity/HumanityBlock/index.js
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Input from 'common/src/components/Input/index.js';
import Button from 'common/src/components/Button/index.js';
import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  ImageWrapper,
} from './contact-us.style.js';

const ContactUs = ({ row, col }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     charityJson {
  //       contactUsData {
  //         image {
  //           publicURL
  //         }
  //         slogan
  //         title
  //         text
  //         lists {
  //           id
  //           text
  //         }
  //       }
  //     }
  //   }
  // `);

  // const { slogan, title, text, lists, image } = data.charityJson.contactUsData;

  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src="../../image/startup-work.jpg" alt="startup-work" />
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading content="Contact Us" />
              <Input label="First Name" />
              <Input label="Last Name" />
              <Input label="Email" />
              <Input label="Message" inputType="textarea" />
              <Button type="submit" title="Submit" />
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// ContactUs style props
ContactUs.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// ContactUs default style
ContactUs.defaultProps = {
  // ContactUs row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // ContactUs col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default ContactUs;
