import React from 'react';
import { Link } from 'gatsby';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import NavbarWrapper, { MenuWrapper, Button } from './navbar.style';

import newLogo from 'common/src/assets/image/charity/new-logo.png';
import discordImage from 'common/src/assets/image/discord-logo.svg';

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar">
      <Container fullWidth={true}>
        <Link className="logo">
          <Image src={newLogo} alt="Global Startup Community Logo" />
        </Link>
        <MenuWrapper>
          <Button onClick={() => window.open('https://discord.gg/9FQU5xs')}>
            <Image src={discordImage} alt="Join Us on Discord!" />
          </Button>
        </MenuWrapper>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
