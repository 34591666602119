import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { facebook } from 'react-icons-kit/fa/facebook';
import { twitter } from 'react-icons-kit/fa/twitter';
import { heart } from 'react-icons-kit/fa/heart';
import { instagram } from 'react-icons-kit/fa/instagram';
import pixeltree from '../../../images/pixeltree.svg';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, {
  List,
  ListItem,
  CopyrightText,
  SocialList,
  SelectWrapper,
} from './footer.style';

const Footer = ({ row, col, colOne, colTwo }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        menuWidgets {
          id
          title
          menu {
            id
            link
            text
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col-two" {...colTwo}>
            {data.charityJson.menuWidgets.map(widget => (
              <Box
                className="col"
                {...col}
                key={`footer__widget-key${widget.id}`}
              >
                <Heading
                  className="widget_title"
                  as="h3"
                  content={widget.title}
                />
                <List>
                  {widget.menu.map(item => (
                    <ListItem key={`list__item-${item.id}`}>
                      <Link to={item.link}>{item.text}</Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of List column */}
        </Box>
        {/* End of widgets row */}
        <Box className="row copyright" {...row}>
          <CopyrightText>
            <CopyrightText>
              <Text className="text" content="Crafted with " />
            </CopyrightText>
            <SocialList>
              <li className="heart">
                <a
                  target="_blank"
                  href="https://pixeltree.ca/"
                  aria-label="social share link"
                >
                  <Icon icon={heart} />
                </a>
              </li>
              <CopyrightText>
                <Text className="text" content="by" />
              </CopyrightText>
              <li className="pixeltree">
                <a
                  target="_blank"
                  href="https://pixeltree.ca/"
                  aria-label="social share link"
                >
                  <img src={pixeltree} />
                </a>
              </li>
            </SocialList>
            <Text
              className="text"
              content="| © 2020 Global Startup Community. All Rights Reserved"
            />
          </CopyrightText>
          <SocialList>
            <li className="linkedin">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/startup-community"
                aria-label="social share link"
              >
                <Icon icon={linkedin} />
              </a>
            </li>
            <li className="facebook">
              <a
                target="_blank"
                href="https://www.facebook.com/Global-StartUp-Community-113225973781559/"
                aria-label="social share link"
              >
                <Icon icon={facebook} />
              </a>
            </li>
            <li className="twitter">
              <a
                target="_blank"
                href="https://twitter.com/GlobalStartupCo"
                aria-label="social share link"
              >
                <Icon icon={twitter} />
              </a>
            </li>
            <li className="instagram">
              <a
                target="_blank"
                href="http://instagram.com/globalstartupco"
                aria-label="social share link"
              >
                <Icon icon={instagram} />
              </a>
            </li>
          </SocialList>
        </Box>
        {/* End of copyright row */}
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '30%'],
    mt: [0, '13px', '0'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '70%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '33.33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default Footer;
