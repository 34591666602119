import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/src/theme/charity';
import { ResetCSS } from 'common/src/assets/css/style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/Charity/Navbar';
import DrawerSection from '../containers/Charity/DrawerSection';
import BannerSection from '../containers/Charity/BannerSection';
import FeatureSection from '../containers/Charity/FeatureSection';
import HumanityBlock from '../containers/Charity/HumanityBlock';
import BlogSection from '../containers/Charity/BlogSection';
import Footer from '../containers/Charity/Footer';
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from '../containers/Charity/charity.style';
import SEO from '../components/seo';
import Subscribe from '../containers/Charity/Subscribe';
import ClientBlock from '../containers/Charity/ClientBlock';
import WorkSection from '../containers/Charity/WorkSection';
import ContactUs from '../containers/ContactUs';

export default () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <SEO title="Global Startup Community" />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper>
            {/* Start content wrapper section */}
            <BannerSection /> {/* SlideShowSection */}
            <SEO name="About Us" />
            <HumanityBlock /> {/* AboutUsSection */}
            <SEO name="Our Team" />
            <WorkSection /> {/* OurTeamSection */}
            <SEO name="Events" />
            <BlogSection /> {/* EventSection */}
            {/* <BranchSection />
            <MilestoneBlock />
            <PromotionBlock />
            <DonateSection />
            <MapSection />
            <FundraiserSection />*/}
          </ContentWrapper>
          {/* End of content wrapper section */}
          <Footer />
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
